import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import StoryblokComponent from "../components/storyblok/storyblokComponent";

function StoryblokPage({ pageContext }) {
  const content = useMemo(() => {
    const { story } = pageContext;
    const parsedContent = JSON.parse(story.content);
    return {
      ...parsedContent,
      firstPublishedAt: story.first_published_at,
      pageName: parsedContent.pageName || parsedContent.pageTitle || story.name,
    };
  }, [pageContext.story.uuid]); //eslint-disable-line

  function selectPageComponent(componentName) {
    switch (componentName) {
      case "page":
        return StoryblokComponent;
      case "caseStudy":
        return StoryblokComponent;
      default:
        return null;
    }
  }

  function renderPageContent() {
    if (!(content || content.body || content.body.length)) {
      return null;
    }

    const StoryblokPageComponent = selectPageComponent(content.component);

    return StoryblokPageComponent ? (
      <StoryblokPageComponent {...content} pageContext={pageContext} />
    ) : (
      <div>Unknown storyblok component</div>
    );
  }

  if (!content) {
    return null;
  }

  return (
    <Layout
      title={content.name}
      description={content.pageDescription}
      image={content.thumbnail.filename}
    >
      <Helmet>
        <title>{`${content.pageName} - Mutetube`}</title>
        <meta name="description" content={content.pageDescription} />
        <meta property="og:title" content={content.pageName} />
        <meta property="og:description" content={content.pageDescription} />
        <meta property="og:image" content={content.thumbnail.filename} />
        <meta property="og:url" content={content.full_slug} />
        <meta name="twitter:title" content={content.pageName} />
        <meta name="twitter:description" content={content.pageDescription} />
        <meta name="twitter:image" content={content.thumbnail.filename} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {renderPageContent()}
    </Layout>
  );
}

export default StoryblokPage;
